@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: 'Inter', sans-serif;
}
::-webkit-scrollbar {
  width: '8px' !important;
}
::-webkit-scrollbar-thumb {
  background-color: '#888' !important;
  border-radius: '4px' !important;
}
::-webkit-scrollbar-thumb:hover {
  background-color: '#555' !important;
}
::-webkit-scrollbar-track {
  background-color: 'background.paper' !important;
}

.test {
  color: #858585be;
}
